const redirectLogin = () => {

  let locationOrigin = window.location.origin;

  if (process.env.REACT_APP_ENV === "development" && !window.location.origin.includes('localhost') && window.location.protocol === 'http:') {
    locationOrigin = locationOrigin.replace('http', 'https');
  }

  const redirectUri = encodeURIComponent(locationOrigin);

  sessionStorage.setItem("recentURL", window.location.pathname + window.location.search);

  const fullUrl = window.location.host
  //window.location.host is subdomain.domain.com
  const parts = fullUrl.split('.');
  const subDomain = parts[0];
  
  let loginUrl =
  `https://sqquber.b2clogin.com/sqquber.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_sq_deb_b2c_openid&client_id=4c026174-9b4e-4e2f-b026-e853810a26f7&nonce=defaultNonce&redirect_uri=${redirectUri}%2Fauth&scope=openid&response_type=id_token&prompt=login`;
  if (process.env.REACT_APP_ENV === "local") {
    loginUrl =
    `https://sqquber.b2clogin.com/sqquber.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_sq_deb_b2c_openid&client_id=4c026174-9b4e-4e2f-b026-e853810a26f7&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth&scope=openid&response_type=id_token&prompt=login`;
  } else if (process.env.REACT_APP_ENV === "development") {
    if (['dev-sq-atnm', 'dashboard-atnm'].indexOf(subDomain) > -1) {
      loginUrl = `https://sqatnm.b2clogin.com/sqatnm.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_sqatnm&client_id=7b30171a-a8bd-493f-96be-07dd38a1b44c&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdev-sq-atnm.azurewebsites.net%2Fauth&scope=openid&response_type=id_token&prompt=login`
    } else {
      loginUrl =
      `https://sqquber.b2clogin.com/sqquber.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_sq_deb_b2c_openid&client_id=4c026174-9b4e-4e2f-b026-e853810a26f7&nonce=defaultNonce&redirect_uri=${redirectUri}%2Fauth&scope=openid&response_type=id_token&prompt=login`;
    }
  } else if (process.env.REACT_APP_ENV === "pre-production") {
    loginUrl =
      `https://sqstaging.b2clogin.com/sqstaging.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_staging_safeyqube&client_id=b4fa3fd3-469b-4975-91ac-9876f8bb382b&nonce=defaultNonce&redirect_uri=${redirectUri}%2Fauth&scope=openid&response_type=id_token&prompt=login`;
  } else if (process.env.REACT_APP_ENV === "production") {
    loginUrl =
      `https://safetyqube.b2clogin.com/safetyqube.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_safeyqube&client_id=8fe26a1b-dd3c-4b72-bcc9-5159c48dbedb&nonce=defaultNonce&redirect_uri=${redirectUri}%2Fauth&scope=openid&response_type=id_token&prompt=login`;
  }

  localStorage.removeItem("token");
  window.location = loginUrl;
};

export default redirectLogin;
